import React from "react";
import { connect } from "react-redux";
import { addDays, isAfter } from "date-fns";
import FirstMonthSwapReminderModal from "../membership/modals/FirstMonthSwapReminderModal";
import { MODAL_NAMES, firstMonthSwapReminderModalHelpers, isModalDismissed } from "helpers/on-load-modal-helpers";
import { membershipStatePropType } from "components/propTypes";
import { parseISOWithoutTime } from "helpers/date-helpers";

export default class OnLoadModal extends React.Component {
  static propTypes = {
    membershipState: membershipStatePropType,
  };

  state = {
    visibleOnLoadModal: null,
  };

  clearModal = () => this.setState({ visibleOnLoadModal: null });

  componentDidMount() {
    this.setModal();
  }

  setModal = () => {
    this.checkRemainingModals();
  };

  checkRemainingModals = () => {
    if (this.isFirstMonthSwapReminderModalEligible()) {
      this.setState({ visibleOnLoadModal: MODAL_NAMES.FIRST_MONTH_SWAP_REMINDER_MODAL });
    }
  };

  /**
   * Determines whether to show the modal to remind users to swap
   * Early returns if there is no membershipState
   * Conditions to show the modal are:
   * - User is a 1 or 2 swap user
   * - User is in their first term
   * - User has no purchases since seven days of their first billing date
   */
  isFirstMonthSwapReminderModalEligible = () => {
    const { membershipState } = this.props;

    if (!membershipState || isModalDismissed(MODAL_NAMES.FIRST_MONTH_SWAP_REMINDER_MODAL)) {
      return false;
    }

    const { hasOrderHistory, membershipTermNumber, monthlyShipmentLimit, termStart } = membershipState;

    const isNewMember = membershipTermNumber === 0;
    const thresholdAfterTermStart = addDays(parseISOWithoutTime(termStart), 6);
    const isWeekAfterTermStart = isAfter(new Date(), thresholdAfterTermStart);

    return monthlyShipmentLimit && isNewMember && !hasOrderHistory && isWeekAfterTermStart;
  };

  getModalMarkup = () => {
    const { membershipState } = this.props;
    const modal = this.state.visibleOnLoadModal;

    switch (modal) {
      case MODAL_NAMES.FIRST_MONTH_SWAP_REMINDER_MODAL:
        return (
          <FirstMonthSwapReminderModal
            onRequestClose={() => this.clearModal()}
            onClickCTA={firstMonthSwapReminderModalHelpers.onClickCTA(membershipState)}
          />
        );
      default:
        return null;
    }
  };

  render() {
    let modal;

    if (this.state.visibleOnLoadModal) {
      modal = this.getModalMarkup();
    }

    return <div>{modal}</div>;
  }
}

function mapStateToProps(state) {
  const { membershipState } = state;

  return { membershipState };
}

export const ConnectedOnLoadModal = connect(mapStateToProps)(OnLoadModal);
