import PropTypes from "prop-types";
import ClosableModal from "components/source/shared/closable-modal";
import AtomFullWidthButton from "components/source/atoms/atom-full-width-button";
import { MODAL_NAMES, setModalDismissed } from "helpers/on-load-modal-helpers";

const FirstMonthSwapReminderModal = ({ onRequestClose, onClickCTA }) => {
  const closableModalClass = "first-month-swap-reminder-modal";
  const baseClass = "swap-reminder-modal";
  const handleClick = () => {
    onRequestClose();
    onClickCTA();
  };

  // Update local storage so this modal does not show again
  setModalDismissed(MODAL_NAMES.FIRST_MONTH_SWAP_REMINDER_MODAL);

  return (
    <ClosableModal
      isCloseHidden={false}
      isOpen={true}
      onRequestClose={() => onRequestClose()}
      optionalClass={closableModalClass}>
      <div className={`${baseClass}`}>
        <div className={`${baseClass}__body__image`} />
        <div className={`${baseClass}__body`}>
          <h3 className={`${baseClass}__body__heading`}>Need some inspiration for your first shipment?</h3>
          <p className={`${baseClass}__body__description proxima-body-copy`}>
            We&apos;ve got some tips to help get you started.
          </p>
        </div>
        <div className={`${baseClass}__cta`}>
          <AtomFullWidthButton onClick={handleClick} buttonText="Learn more" />
        </div>
      </div>
    </ClosableModal>
  );
};

FirstMonthSwapReminderModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  onClickCTA: PropTypes.func.isRequired,
};

export default FirstMonthSwapReminderModal;

export const { propTypes } = FirstMonthSwapReminderModal;
