import { logAction } from "action-logger";
import { LocalStorage } from "../site/localStorage";

export const MODAL_NAMES = {
  UPGRADES_MODAL: "upgradesModal",
  FIRST_MONTH_SWAP_REMINDER_MODAL: "firstMonthSwapReminderModal",
};

export const MODAL_KEYS = {
  [MODAL_NAMES.FIRST_MONTH_SWAP_REMINDER_MODAL]: {
    storeKey: "first-month-swap-reminder-modal",
    dismissedKey: "firstMonthSwapReminderModalDismissed",
  },
};

/**
 * A grouping of functions to be used for a modal
 * Why an object instead of adding to the main export?
 * Primarily to keep things cleaner in the main export and group related functionality.
 * If we want to continue this model, we won't have to worry about function naming conflicts since the inner functions
 * are essentially namespaced.
 */
export const firstMonthSwapReminderModalHelpers = {
  /**
   * Opens a RTRShift page in a new tab and logs a pixel
   * @param {Object} membershipState
   * @returns {Function} The callback to be used once the CTA is clicked
   */
  onClickCTA: membershipState => () => {
    window.open("https://rtrshift.com/unlimited-membership-pickingitems-video/", "_blank", "noopener");
    logAction({
      object_type: "swap_reminder_modal",
      action_type: "click_learn_more",
      membership_id: membershipState.membershipId,
      membership_tier: membershipState.membershipTierName,
      user_id: membershipState.id,
      n_swaps: membershipState.shipmentsCount,
    });
  },
};

export function isModalDismissed(modalName) {
  const storeKey = MODAL_KEYS[modalName].storeKey;
  const dismissedKey = MODAL_KEYS[modalName].dismissedKey;

  const localStorageClient = new LocalStorage(storeKey);
  return !!localStorageClient.get(dismissedKey);
}

export function setModalDismissed(modalName) {
  const storeKey = MODAL_KEYS[modalName].storeKey;
  const dismissedKey = MODAL_KEYS[modalName].dismissedKey;

  const localStorageClient = new LocalStorage(storeKey);
  localStorageClient.set(dismissedKey, {
    dismissedAt: new Date().getTime(),
  });
}

const OnLoadModalHelpers = {
  MODAL_NAMES,
  MODAL_KEYS,
  isModalDismissed,
  setModalDismissed,
  // Specific Modal Helpers
  firstMonthSwapReminderModalHelpers,
};

export default OnLoadModalHelpers;
